<template>
  <div>
    <!--  -->
    <div id="RemoteVideo" style="z-index: -1; top:-200vh;">
    </div>
    <canvas id="userDefaultCanvas" width="64px" height="64px"></canvas>
</div>
</template>
<script>
import VERTC from '@volcengine/rtc';
import { StreamIndex,MediaType } from '@volcengine/rtc';
import axios from 'axios';
export default {
  data() {
    return {
      engine: null,  //火山rtc实例
      token:null,  
      roomId:null,
      teacherId:null, 
      remoteId:null,
      defaultStream:null, //默认流
    }
  },
  props: ['audioStream'],
  methods: {
    leaveRoom(){
      if(!this.engine){return}
      this.engine.leaveRoom();
      console.log('已经退出房间');
    },
    async initRTC(){
     
      this.engine = await VERTC.createEngine('66978ec5770dbb01a48f122c');
      console.log(this.engine);
      this.getToken()
      // this.joinRoom()
    },
    async getToken(){
      this.roomId = store.get('roomId')
      // await this.getSign()
      this.teacherId = store.get('identifier');
      axios.get(`https://tiku.ixunke.com/api/v1/rtcroom/token?roomID=${this.roomId}&userID=${this.teacherId}`).then(res=>{
        console.log(res.data.data.token);
        this.token = res.data.data.token;
        // this.token = '00166978ec5770dbb01a48f122cMgD/oJkvrMSxZiwWs2YBADEBADEFAAAALBazZgEALBazZgIALBazZgMALBazZgQAAAAAACAA5qI5ani34CNKMHOaylyBFUTFr+RuhJTPwbtIaWxgzMY=';
        this.joinRoom()
      }).catch(error=>{
        this.$message.error('获取连麦token出错了，请联系技术支持')
      })
      // 
    },
    async joinRoom() {
     
      console.log('开始进入房间');
      const config = {
        // appId: '65ddacd336fac5016bf70e89',
        appId:'66978ec5770dbb01a48f122c',
        // roomId: '1',
        roomId: this.roomId,
        uid:this.teacherId,
        // token:'00166978ec5770dbb01a48f122cMgD/oJkvasaxZuoXs2YBADEBADEFAAAA6hezZgEA6hezZgIA6hezZgMA6hezZgQAAAAAACAAxwVY5EEx6yWv6gjoRjL6PPt9TX79qLNuhaGTZVLKJyw=',
        token: this.token,
      };
      try {
        await this.engine.joinRoom(
          config.token,
          config.roomId,
          {
            userId: config.uid,
          },
          {
            isAutoPublish: true, // 采集音视频时自动发布到房间
            isAutoSubscribeAudio: true, // 自动订阅音频
            isAutoSubscribeVideo: true, // 自动订阅视频
          }
        )
      } catch (e) {
        console.error('进房错误', e);
      }
      this.createLocalStream()
  
      this.engine.on(VERTC.events.onUserLeave, this.handleUserLeave);
      // this.engine.on(VERTC.events.onUserJoined, this.handleUserJoin);      // this.engine.on(VERTC.events.onUserUnpublishStream, this.onUserUnpublishStream);
      this.engine.on(VERTC.events.onUserPublishStream, this.handleUserPublishStream);
      this.engine.on(VERTC.events.onUserUnpublishStream, this.handleUserUnpublishStream);
    },
    async createLocalStream() {
      const canvas = document.getElementById('canvas');
      const stream = canvas.captureStream();
      const videoTrack = stream.getVideoTracks()[0];

      this.engine.setVideoSourceType(
        0,
        0
      );
      this.engine.setAudioSourceType(
        0,
        0
      );
      console.log(this.audioStream.getAudioTracks()[0]);
      this.engine.setExternalAudioTrack(
        0,this.audioStream.getAudioTracks()[0]
      );
      this.engine.setExternalVideoTrack(
        0,videoTrack
      );
      console.log('开始发布本地流');
    },
    // 接收到远端进入房间消息
    async handleUserJoin(user){
      console.log('onUserJoined');
      console.log(user);
    },
    async handleUserLeave(e){
      // console.log('handleUserLeave', e);
      // console.log(e.userInfo.userId);
      let id = e.userInfo.userId
      if(id == this.remoteId){
        this.$emit('closeLianmai')
      }
    },
    async onUserLeave(stream){
      console.log(stream);
      let mediaType = stream.mediaType;
     
    },
    async handleUserUnpublishStream(stream){
      const { userId, mediaType } = stream;
      console.log('handleUserUnpublishStream');
      console.log(userId, mediaType);
      if(mediaType==2){
        this.$emit('handleUserUnpublishVideo');
        const player = document.getElementById('RemoteVideo');
        player.innerHTML = '';
      }
    },
    async handleUserPublishStream(stream) {
        console.log('新增了一个远端流', stream);
        const { userId, mediaType } = stream;
        this.remoteId = userId;
        const remoteUserId = userId;
        // console.log(userId, mediaType);
       
        if (mediaType) {
          // console.log(mediaType);
          const player = document.getElementById('RemoteVideo');
          if (player) {
            if(mediaType==2 || mediaType==3){
              await this.engine.setRemoteVideoPlayer(0, {
                userId: remoteUserId,
                renderDom: player,
                playerId:'RemoteVideoDom',
                contentHint:'motion',
                VideoRenderMode:0,
              });
              this.$emit('RemotePublishStreamVideo');
            }
            let video = player.querySelector('video');
            if(mediaType==1 || mediaType==3){
              setTimeout(async() => {
                let remoteAudio = this.engine.getRemoteStreamTrack(remoteUserId,0,'audio');
                // console.log(remoteAudio);
                // console.log('---------remoteAudio--------');
                this.$emit('RemotePublishStreamAudio',remoteAudio);
              }, 1000);
            }            
          }
         
        }
    }
  },
  async mounted() {
    // this.joinRoom()
  },
  beforeDestroy(){
    this.leaveRoom()
  }
}
</script>
<style lang="scss" scoped>
#RemoteVideo{
  width: 480px;
  height: 320px;
  position: fixed;
}
#userDefaultCanvas{
  position: absolute;
  z-index: -1;
  top:-100vh;
}
</style>