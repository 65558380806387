<template>
  <div class="message-image" :style="{ textAlign: textRight ? 'right' : '' }">
    <a-tooltip
      overlayClassName="ixunke-tooltip"
      :placement="textRight ? 'left' : 'right'"
      trigger="oncontextmenu"
      v-model="tipShow"
      :getPopupContainer="
        (triggerNode) => {
          return triggerNode.parentNode || document.body
        }
      "
    >
      <template slot="title">
        <div @click="messageEvent('copy')">复制</div>
        <div @click="messageEvent('revoke')">撤回</div>
      </template>
      <img
        :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
        :src="imageUrl"
        @click="previewModalEvent"
      />
    </a-tooltip>
    <messagePreview :previewModal.sync="previewModal" previewType="image" :previewContent="previewContent" />
  </div>
</template>

<script>
import messagePreview from './message-preview.vue'

export default {
  props: ['payload', 'textRight'],
  components: {
    messagePreview,
  },
  data() {
    return {
      tipShow: false,
      //previewModal
      previewModal: false,
      previewContent: this.payload.imageUrl,
    }
  },
  computed: {
    imageUrl() {
      return this.formatImageUrl(this.payload.imageUrl, 300, 300, 2)
    },

    imageWidth() {
      console.log(this.payload)
      let w = 100
      if (this.payload && this.payload.imageInfoArray.length) {
        w = this.payload.imageInfoArray[0].width 
      }
      return Math.min(w, 100)
    },

    imageHeight() {
      let w = this.payload.imageInfoArray[0].width
      let h = this.payload.imageInfoArray[0].height
      if (w && h) {
        return (h / w) * this.imageWidth
      }
      return this.imageWidth
    },
  },
  methods: {
    formatImageUrl(url, width = 200, height = 200, rule = 1) {
      if (url) {
        let localServer = 0
        if (url.indexOf('https://wx') == 0 || url.indexOf('https://thirdwx') == 0) {
          // 微信头像不能加后缀
          return url
        } else if (~url.indexOf('/resize/')) {
          return url
        } else {
          if (url.indexOf('http') != 0) {
            // http开头不加HOST
            url = HOST + url
            localServer = 1
          }
          let baishan = /(ixunke-static.)|(xuntiku-static.)|(static\d+.)|(video\d+.)/.test(url)
          if (baishan || localServer) {
            if (rule == 1) {
              if (~url.indexOf('.cn')) {
                return url.replace('.cn', `.cn/resize/af_w_${width}_h_${height}`)
              } else if (~url.indexOf('.com')) {
                return url.replace('.com', `.com/resize/af_w_${width}_h_${height}`)
              }
            } else if (rule == 2) {
              if (~url.indexOf('.cn')) {
                return url.replace('.cn', `.cn/resize/wf_w_${width}`)
              } else if (~url.indexOf('.com')) {
                return url.replace('.com', `.com/resize/wf_w_${width}`)
              }
            } else {
              if (~url.indexOf('.cn')) {
                return url.replace('.cn', `.cn/resize/hf_h_${height}`)
              } else if (~url.indexOf('.com')) {
                return url.replace('.com', `.com/resize/hf_h_${height}`)
              }
            }
          } else {
            if (rule == 1) {
              return url + `?imageView2/1/w/${width}/h/${height}`
            } else if (rule == 2) {
              return url + `?imageView2/2/w/${width}`
            } else if (rule == 3) {
              return url + `?imageView2/2/h/${height}`
            }
          }
        }
      } else {
        return ''
      }
    },
    messageEvent(action) {
      this.$emit('messageAction', action)
      this.tipShow = false
    },
    previewModalEvent() {
      this.previewModal = true
    },
  },
}
</script>

<style lang="css" scoped>
.message-image {
  position: relative;
  /* margin-top: 12px; */
}
img {
  display: inline-block;
  border: 1px solid #eee;
  background-color: #eee;
  border-radius: 4px;
  margin: 6px 20px;
}
</style>
