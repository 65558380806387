<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="550px">
      <!-- <p class="title">课件库</p> -->
      <!-- v-if="!uploadSuccess" -->
      <el-upload
        class="upload-demo"
        :on-change="handleChange"
        :action="uploadUrl"
        :show-file-list="false"
        multiple
        :auto-upload="false"
        :file-list="FileList"
        :before-upload="handleBefore"
      >
        <!-- <i class="el-icon-upload"></i> -->
        <el-button size="small" type="primary">上传课件</el-button>
        <el-button size="small" @click.stop="refresh()">刷新</el-button>
        <!-- <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div> -->
        <div class="el-upload__tip" slot="tip">
          支持PDF/PPT/PPTX/DOC/DOCX/XLS/XLSL格式，大小不超过100M
        </div>
        <div class="el-upload__tip" slot="tip">
          建议课件长宽比例为<span style="color: #ff3535">16:9</span>
        </div>
        <div class="el-upload__tip" slot="tip">
          每节课程暂时仅支持上传1份课件
        </div>
        <div class="el-upload__tip" slot="tip">
          预计转码时间1分钟左右
        </div>
      </el-upload>
      <div class="Courseware_list">
        <div class="Courseware_title">
          <span>文档名称</span>
          <span>状态</span>
          <span>操作</span>
        </div>
        <ul style="padding: 0">
          <li v-for="(item, index) in CoursewareData" :key="index">
            <div class="Courseware_name">{{ item.name }}</div>
            <div class="Courseware_state">{{ Convert(item.state) }}</div>
            <div class="fnc" v-if="item.state == 3 ||item.state == 4">
              <span v-if="item.state == 3 "
                style="border-right: 1px solid #7e7c7c; padding-right: 10px"
                @click="replaceCourseware(item.id)"
                >演示</span
              >
              <span style="margin-left: 10px" @click.stop="del(item.id)"
                >删除</span
              >
            </div>
          </li>
        </ul>
      </div>

      <!--  v-else -->
      <div v-if="flag" style="text-align: left; font-size: 16px;color: #ff4b05;">
        {{ flag }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
const http = window.require("https");
const crypto = window.require("crypto");
const path = window.require('path');
const fs = window.require('fs');
import { ixunkeAxios } from "@/assets/js/axios.js";
import moment from "moment";
import axios from "axios";
import * as qiniu from "qiniu-js";
const Store = window.require("electron-store");
const store = new Store();
export default {
  name: "upload",
  props: [
    "CoursewareID",
    "CoursewareListLength",
    "Courseware",
    "roomId",
    "lessonId",
  ],
  data() {
    return {
      dialogVisible: false,
      host: "",
      pubilcDomin: "",
      privateDomin: "",
      upInfo: null,
      uploadUrl: "",
      uploadToken: "",
      CoursewareData: [],
      //s3上传
      s3Uploader: null,
      //s3上传 acl
      s3UploadAcl: "",
      //s3上传 bucket
      s3UploadBucket: "",
      //s3上传时判断文件类型依据
      s3UploadTypeArr: [],
      //s3上传结果
      s3ResponseJSON: {},

      uploadSuccess: false,
      flag:null,
      uploadDomain: null,
      FileList: [],
      tmpFileUrl:'',  //临时url存储
    };
  },
  mounted() {
    // console.log(this.Courseware);
    // console.log("----------------");
    this.refresh();

    this.CoursewareData = this.Courseware;
    if(!this.CoursewareData){
      this.CoursewareData=  []
    }
    
  },
  created() {
    this.host = store.get("host");

    this.getUpInfo();
  },
  methods: {
    handleBefore(file) {
      console.log(file);
      return false
      let acceptFileType = [
        "docx",
        "doc",
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "pdf",
      ];
      let type = file[0].type.toLowerCase();

      console.log(type);

      if (!acceptFileType.includes(type)) {
        setTimeout(() => {
          this.$notify({
            title: "温馨提示",

            message: "格式错误！",

            type: "error",
          });
        }, 50);

        return false;
      }

      return true;
    },
    replaceCourseware(id) {
      if(!id){
        console.log('暂用本地缓存图片');
        this.$emit("replaceCourseware", id);
      }else{
        this.$emit("replaceCourseware", id);
      }
      this.dialogVisible = false;
      // this.$message({
      //   type: "success",
      //   message: "切换课件成功",
      //   duration: 600,
      // });
    },
    refresh() {
      // console.log("刷新");
      // for (let index = 0; index < wpsList.length; index++) {
      //   const url = wpsList[index].url;
      //   this.downloadFileToFolder(`${index}`,'png',url)
      // }
      // if(!this.CoursewareData){
      //   this.CoursewareData = []
      // }
      // this.CoursewareData.push({
      //   name:'高级古风.pptx',
      //   state:3,
      // })
      // console.log(this.CoursewareData);
      this.getCoursewareID();
    },
    Convert(state) {
      switch (state) {
        case 1:
          return "转码中";
        case 2:
          return "转码中";
        case 3:
          return "转码成功";
        case 4:
          return "转码失败";
        default:
          return "";
      }
    },
    async del(id) {
      const axiosUrl = "/api/courseware/del";
      let subData = {
        id: id,
      };
      await ixunkeAxios(subData, "post", axiosUrl).then((res) => {
        // console.log(res);
        if (res.errno == 0) {
          this.$message.success("删除课件成功");
          this.refresh();
        }
      });
    },
    async getCoursewareID() {
      const axiosUrl = "/api/courseware/list";
      let subData = {};
      subData.roomId = this.lessonId;
      subData.page = 1;
      subData.pageSize = 99;
      await ixunkeAxios(subData, "get", axiosUrl).then((res) => {
        this.CoursewareCount = res.count || 0;
        if (res.count == 0) {
          // this.checkKJ();
          this.CoursewareData = []
          this.$emit('destroyboard')
        }
        // console.log(res.data[0].id);
        if (res.data.length == 0) return;
        // console.log(this.CoursewareData.at(-1)?.state);
        this.CoursewareData = res.data;
        if(this.CoursewareData.at(-1)?.state==3){
          clearInterval(this.timer)
          this.flag = null;
          this.timer  = null
        }
        if(!this.CoursewareData){
          this.CoursewareData=  []
        }
        // console.log("-------------------");
        // console.log(this.CoursewareData);
      });
    },
    
    uploadAllCompleteEvent(path) {
      let subData = {
        roomId: this.roomId,
        path: path,
        name: "",
        imglist: [],
      };
      // ixunkeAxios(subData, "post", "/api/courseware/set")
      //   .then((res) => {
      //     if (res.errno == 0) {
      //       this.refresh()
      //       // this.dialogVisible = false;
      //       // this.$notification.success({
      //       //   message: "上传成功",
      //       // });
      //     } else {
      //       this.$error({
      //         centered: true,
      //         title: "上传失败！",
      //         content: "课件上传失败！" + JSON.stringify(res.errmsg),
      //         okText: "知道了",
      //       });
      //     }
      //     this.tableLoading.spinning = false;
      //   })
      //   .catch((err) => {

      //   });
    },
    async checkdialogVisible() {
      this.dialogVisible = true;
    },
    unix(len, radix) {
      let chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
          ""
        );
      let uuid = [];
      radix = radix || chars.length;
      for (let i = 0; i < len; i++)
        uuid[i] = chars[0 | (Math.random() * radix)];
      return uuid.join("");
    },
    // 文件列表变化
    async handleChange(file, fileList) {
      let key =
        "files" +
        "/" +
        moment().format("YYYY-MM-DD") +
        "/" +
        moment().unix() +
        this.unix(4) +
        "." +
        file.name.substr(file.name.lastIndexOf(".") + 1);
 
      let item = {
        key: key,
        file: file,
        info: {
          fileId: key,
          name: file.name.substring(0, file.name.lastIndexOf(".")),
          ext: file.name.substr(file.name.lastIndexOf(".") + 1),
          upProcess: 0,
          status: "",
          mediaId: 0,
          size: file.size,
          isDidPersistent: 0,
          transcode: 0,
          play: false,
        },
      };
      console.log(file.size/1024/1024);
      if(file.size/1024/1024>100){
        
          this.$notify({
            title: "温馨提示",

            message: "文件大小超过100MB",

            type: "error",
          });
          this.FileList = [];
        return false;
      }

      let acceptFileType = [
        "docx",
        "doc",
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "pdf",
      ];
      let type = item.info.ext.toLowerCase();


      if (!acceptFileType.includes(type)) {
        setTimeout(() => {
          this.$notify({
            title: "温馨提示",

            message: "格式错误！",

            type: "error",
          });
          this.FileList = [];
        }, 50);

        return false;
      }
      this.flag = '正在上传文档，请勿关闭窗口'
      if (this.upInfo.type == "qiniu") {
        this.uploadUrl = "https://upload.qiniup.com/";
        let tokenType = "static";
        await ixunkeAxios(
          { domainType: "static" },
          "get",
          "/api/qiniu/domain"
        ).then((res) => {
          // console.log(res, "--result-");
          if (res.errno === 0) {
            res.data.domain =
              this.upInfo.httpStr + "://" + res.data.staticDomain;
            this.uploadDomain = res.data.domain;
          }
        });
        ixunkeAxios(
          { tokenType: tokenType },
          "get",
          "/api/qiniu/manage_uptoken"
        )
          .then((res) => {
            this.uploadToken = res.uptoken;
            this.qiniuUploadEvent(key, file);
          })
          .catch((error) => {
            this.$error({
              centered: true,
              title: "上传令牌获取失败！",
              content: JSON.stringify(error),
              okText: "知道了",
            });
          });
        //
      } else if (this.upInfo.type == "s3") {
        this.s3UploadEvent(key, file);
      }

      //   this.s3Uploader.methods.clearStoredFiles();
      //   this.s3Uploader.methods.addFiles(file);
    },
    //S3上传
    s3UploadEvent(key, file,local=false) {
      let signUrl = "/api/v1/cloud_storage/upload?type=sign";
      let successUrl = "/api/v1/cloud_storage/upload?type=success";
      let date = this.upInfo.date.split("T")[0];
      let conditions = [
        {
          acl: "public-read",
        },
        {
          bucket: this.s3UploadBucket,
        },
        {
          success_action_status: "200",
        },
        {
          "x-amz-algorithm": "AWS4-HMAC-SHA256",
        },
        {
          "x-amz-credential": `${this.upInfo.accessKeyId}/${date}/${this.upInfo.region}/s3/aws4_request`,
        },
        {
          "x-amz-date": this.upInfo.date,
        },
      ];
      conditions.push({
        key: key,
      });
      conditions.push({
        "Content-Type": "file",
      });
      let getSignData = {
        expiration: this.upInfo.expiration,
        conditions: conditions,
      };
      axios
        .post(`${this.host}/api/v1/cloud_storage/upload?type=sign`, getSignData)
        .then((resData) => {
          let params = new FormData();
          var blob 
          if(local){
            // console.log(file['content']);
            blob= new Blob([file['content']], { type: file.type });
            // console.log(blob);
          }else{
            const chunk = file.raw.slice(0, file.size);
            blob = new Blob([chunk], { type: file.type });
          }
         
          // console.log(file, file.name, "this.s3UploadBucket");
          params.append("acl", "public-read");
          params.append("bucket", this.s3UploadBucket);
          params.append("success_action_status", 200);
          params.append("x-amz-algorithm", "AWS4-HMAC-SHA256");
          params.append(
            "x-amz-credential",
            `${this.upInfo.accessKeyId}/${date}/${this.upInfo.region}/s3/aws4_request`
          );
          params.append("x-amz-date", this.upInfo.date);
          params.append("key", key);
          params.append("Content-Type", "file");
          params.append("Policy", resData.data.policy);
          params.append("x-amz-signature", resData.data.signature);
          params.append("file", blob, file.name);
          //   console.log(this.uploadUrl, params, "-------");
          axios
            .post(this.uploadUrl, params)
            .then((res) => {
              if(local){
                console.log('完成上传');
                // console.log(`${this.uploadUrl}/${key}`);
              }else{
                this.uploadSuccess = true;
                this.flag = "正在转码，请稍候";
                // console.log(`${this.uploadUrl}/${key}`);
                // this.WpsToPng(`${this.uploadUrl}/${key}`, file.name);
                this.setFileUrl(`${this.uploadUrl}/${key}`, file.name)
              }
              
              // this.uploadAllCompleteEvent(`${this.uploadUrl}/${key}`);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //七牛上传
    qiniuUploadEvent(key, file,local=false) {
      const _this = this;
      var blob 
      if(local){
        // console.log(file['content']);
        blob= new Blob([file['content']], { type: file.type });
      }else{
        const chunk = file.raw.slice(0, file.size);
        blob = new Blob([chunk], { type: file.type });
      }
      let qiniuUploader = qiniu.upload(
        blob,
        key,
        this.uploadToken,
        {
          fname: file.name.substring(0, file.name.lastIndexOf(".")),
          params: {},
          // mimeType: this.qiniuUploadTypeArr,
        },
        {
          useCdnDomain: true,
          upprotocol: "https",
          region: null,
        }
      );
      qiniuUploader.subscribe({
        next: (result) => {
          this.$nextTick(() => {
            console.log(parseInt(result.total.percent.key));
          });
        },
        error: (errResult) => {
          console.log(errResult);
        },
        complete: (res) => {
          console.log(`${_this.uploadDomain}/${res.key}`);
          // this.uploadAllCompleteEvent(`${this.uploadDomain}/${res.key}`);
          if(local){
            console.log('完成上传');
            // console.log(`${this.uploadUrl}/${key}`);
          }else{
            _this.uploadSuccess = true;
            _this.flag = "正在转码，请稍候";
            // console.log(`${this.uploadUrl}/${key}`);
            _this.setFileUrl(`${this.uploadDomain}/${res.key}`, file.name)
            // _this.WpsToPng(`${this.uploadDomain}/${res.key}`, file.name);to
          }
        },
      });
    },
    //获取网站上传配置
    async getUpInfo() {
      const httpStr = "https";
      let url = this.host;
      let newHttpStr = "";
      if (url.toUpperCase().indexOf(httpStr.toUpperCase()) < 0) {
        newHttpStr = "http";
      } else {
        newHttpStr = "https";
      }
      let result = await ixunkeAxios(
        { type: "info" },
        "get",
        "/api/v1/cloud_storage/upload"
      );

      //   console.log(result, "=======result==========");
      if (result.errno === 0) {
        result.data.httpStr = result.data.SSL ? "https" : "http";
        // console.log(result.data);
        if (result.data.type == "qiniu") {
          console.log("qiniu云");
          //   dispatch("getPubilcDomin");
          //   dispatch("getPrivateDomin");
        } else if (result.data.type == "s3") {
          this.pubilcDomin = newHttpStr + "://" + result.data.publicCDN;
          this.privateDomin = newHttpStr + "://" + result.data.privateCDN;
          this.uploadUrl = `${newHttpStr}://${result.data.publicBucket}.${result.data.endpoint}`;
          // console.log(this.uploadUrl);

          this.uploadDomain = newHttpStr + "://" + result.data.publicCDN;
          this.s3UploadAcl = "public-read";
          this.s3UploadBucket = result.data.publicBucket;
        }
        this.upInfo = result.data;
      } else {
        result.data = {
          httpStr: newHttpStr,
        };
        console.log("SET_UP_INFO", result);
      }

      //   console.log(this.upInfo, "-------getUpInfo--------");
      return result;
    },
    //获取网站pubilcDomin
    async getPubilcDomin({ commit, state }) {
      let result = await ixunkeAxios(
        { domainType: "static" },
        "get",
        "/api/qiniu/domain"
      );
      if (result.errno === 0) {
        result.data.domain =
          state.upInfo.httpStr + "://" + result.data.staticDomain;
        commit("SET_PUBLIC_DOMIN", result.data.domain);
      }
      return result;
    },
    //获取网站privateDomin
    async getPrivateDomin({ commit, state }) {
      let result = await ixunkeAxios(
        { domainType: "dynamic" },
        "get",
        "/api/qiniu/domain"
      );
      if (result.errno === 0) {
        result.data.domain =
          state.upInfo.httpStr + "://" + result.data.staticDomain;
        commit("SET_PRIVATE_DOMIN", result.data.domain);
      }
      return result;
    },
    async setFileUrl(url, name){
      let subData = {
        roomId: this.roomId,
        path: url,
        name:name,
        platform:"wps"
      };
      console.log(subData,'------set数据-----');
      ixunkeAxios(subData, "post", "/api/courseware/set")
        .then((res) => {
          if (res.errno == 0) {
         
          this.timer = setInterval(() => {
            this.getCoursewareID();
          }, 2000);

            // this.CoursewareData.at(-1).state = 3
          } else {
            this.$error({
              centered: true,
              title: "上传失败！",
              content: "课件上传失败！" + JSON.stringify(res.errmsg),
              okText: "知道了",
            });
          }
        })
        .catch((err) => {

        });
    },
    async WpsToPng(url, name) {
      const _this = this;
      console.log(url, name);
      this.tmpFileUrl = url
      const reqdata = {
        url: url,
        filename: name,
      };
      function md5(obj) {
        const jsonString = JSON.stringify(obj);
        const hash = crypto.createHash("md5");
        hash.update(jsonString);
        return hash.digest("hex");
      }
      function sha1(data) {
        const hash = crypto.createHash("sha1");
        hash.update(data);
        return hash.digest("hex");
      }

      const options = {
        method: "POST",
        hostname: "solution.wps.cn",
        port: null,
        path: "/api/developer/v1/office/convert/to/png",
        headers: {
          Date: new Date().toUTCString(),
          "Content-Md5": md5(reqdata),
          "Content-Type": "application/json",
          Authorization: `WPS-2:AK20231206AGSFWG:${sha1(
            "bBFhZEoItUNkiGeKaGTlzvltySkgdkCK" +
              md5(reqdata) +
              "application/json" +
              new Date().toUTCString()
          )}`,
        },
      };

      const req = http.request(options, function (res) {
        const chunks = [];

        res.on("data", function (chunk) {
          // 将 Uint8Array 解码为字符串

          chunks.push(chunk);
        });

        res.on("end", function () {
          console.log(chunks);
          let list = [];
          chunks.forEach((data) => {
            list.push(data);
          });
          console.log(list);
          const decoder = new TextDecoder("utf-8");
          const jsonString = decoder.decode(list[0]);
          // 将字符串解析为 JSON 对象
          const jsonObject = JSON.parse(jsonString);
          let resData = jsonObject.data;
          if (resData.code == 0) {
            console.log(resData.data.task_id);
            this.flag = "正在转码，请稍候";
            // store.set(url, resData.data.task_id);
            // task_id: "open:naqvsiuejlmwfhuyyannawjsrtzjfqt"
          }
          console.log(jsonObject.data);
          _this.CoursewareData.push({ name: name, state: 2, progress: 0 });

          // _this.timer = setInterval(() => {
          //   _this.tasksID(jsonObject.data.task_id);
          // }, 600);
        });
      });

      // store.set(url,"open:naqvsiuejlmwfhuyyannawjsrtzjfqt")
      // console.log(this.CoursewareData);
      req.write(JSON.stringify(reqdata));
      req.end();
    },
    // async tasksID(id) {
    //   const _this = this;
    //   const path = `/api/developer/v1/tasks/${id}`;
    //   console.log(path);
    //   function md5(obj) {
    //     const hash = crypto.createHash("md5");
    //     hash.update(obj);
    //     return hash.digest("hex").toLowerCase();
    //   }
    //   function sha1(data) {
    //     const hash = crypto.createHash("sha1");
    //     hash.update(data);
    //     return hash.digest("hex");
    //   }
    //   const options = {
    //     method: "GET",
    //     hostname: "solution.wps.cn",
    //     port: null,
    //     path: path,
    //     headers: {
    //       Date: new Date().toUTCString(),
    //       "Content-Md5": md5(path),
    //       "Content-Type": "application/json",
    //       Authorization: `WPS-2:AK20231206AGSFWG:${sha1(
    //         "bBFhZEoItUNkiGeKaGTlzvltySkgdkCK" +
    //           md5(path) +
    //           "application/json" +
    //           new Date().toUTCString()
    //       )}`,
    //     },
    //   };

    //   const req = http.request(options, function (res) {
    //     const chunks = [];

    //     res.on("data", function (chunk) {
    //       chunks.push(chunk);
    //     });

    //     res.on("end", function () {
    //       let list = new Uint8Array(
    //         chunks.reduce((totalLength, chunk) => totalLength + chunk.length, 0)
    //       );
    //       let offset = 0;

    //       for (let index = 0; index < chunks.length; index++) {
    //         const element = chunks[index];
    //         list.set(element, offset);
    //         offset += element.length;
    //       }
    //       console.log(list);
    //       const decoder = new TextDecoder("utf-8");
    //       const jsonString = decoder.decode(list);
    //       // 将字符串解析为 JSON 对象
    //       const jsonObject = jsonString.toString();
    //       console.log(JSON.parse(jsonObject));
    //       const resData = JSON.parse(jsonObject);
    //       if (resData.code == 0) {
    //         const lastData = _this.CoursewareData.at(-1);
    //         if (resData.data.progress == 100) {
    //           // lastData.state = 3;
    //           // store.set(_this.lessonId, resData.data.result.images);
           
    //            clearInterval(_this.timer);
    //           console.log("定时器已结束");
    //           _this.downloadWpsImg(resData.data.result.images)
              
    //           _this.timer = null;
    //         }
    //       }
    //     });
    //   });

    //   req.end();
    // },
    // async downloadWpsImg(wpsList){
    //   const x =  this.unix(6)
    //   let tmplist = [];
    //   const promises = [];
    //   const targetFolder = './download'; // this is a relative path

    //   fs.access(targetFolder, (err) => {
    //       if (err) {
    //           console.log("Directory does not exist");
    //           fs.mkdir(targetFolder, { recursive: true }, (err) => {
    //               if (err) throw err;
    //               console.log("Directory created");
    //           });
    //       } else {
    //           console.log("Directory exists");
    //       }
    //   });

    //   for (let index = 0; index < wpsList.length; index++) {
    //     const url = wpsList[index].url;
    //     let key =
    //       "files" +
    //       "/" +
    //       moment().format("YYYY-MM-DD") +
    //       "/" +
    //       this.roomId +
    //       x +
    //       "/" +
    //       index +
    //       ".png";

    //     const promise = this.downloadFileToFolder(`${index}`, 'png', url)
    //       .then(() => {
    //         setTimeout(() => {
    //           this.uploadLocalImgTo(index, x, key);

    //         }, 100);
    //         return { url: `${this.uploadUrl}/${key}` };
    //       });

    //     promises.push(promise);
    //   }

    //   Promise.all(promises)
    //     .then((results) => {
    //       tmplist = results;
    //       console.log(tmplist);
    //       setTimeout(() => {
    //         this.SetServerData(tmplist)

    //       }, 1000);
    //     })
    //     .catch((err) => {
    //       console.error('处理过程中出现错误:', err);
    //     });
      
    // },
    // async uploadLocalImgTo(index,x,key){
    //     const targetFolder = './download'; // this is a relative path
    //     const filePath = path.join(targetFolder, `${index}.png`);
    //     const fileContent = fs.readFileSync(filePath)
    //     console.log(fileContent);
    //     // const fileContent = fs.readFileSync(`C:\\Program Files\\Ixunke\\live\\download\\${index}.png`);
    //     const file = {
    //       name: `${index}`,
    //       type: `image/png`,
    //       size: fileContent.length,
    //       content: fileContent
    //     };
       
    //     if (this.upInfo.type == "qiniu"){
    //       this.qiniuUploadEvent(key, file,true);
    //     }else if(this.upInfo.type == "s3"){
    //       this.s3UploadEvent(key, file,true)

    //     }  
    // },
    SetServerData(tmplist){
      let subData = {
        roomId: this.roomId,
        path: this.tmpFileUrl,
        name: "",
        images: tmplist,
        platform:"wps"
      };
      console.log(subData,'------set数据-----');
      ixunkeAxios(subData, "post", "/api/courseware/set")
        .then((res) => {
          if (res.errno == 0) {
            setTimeout(() => {
              this.refresh()
            }, 400);
            
            this.$notification.success({
              message: "转码成功",
            });

            this.CoursewareData.at(-1).state = 3
          } else {
            this.$error({
              centered: true,
              title: "上传失败！",
              content: "课件上传失败！" + JSON.stringify(res.errmsg),
              okText: "知道了",
            });
          }
        })
        .catch((err) => {

        });
    },
  },
  watch: {
    CoursewareID(newVal, oldVal) {
      // console.log(newVal);
      this.uploadSuccess = true;
      this.flag = "正在转码，请稍候";
    },
    CoursewareListLength(newVal, oldVal) {
      // console.log(newVal);
      if (newVal > 0) {
        this.flag = "转码完成";
      }
    },
    dialogVisible(newVal, oldVal) {
      // console.log(newVal);
      if (newVal) {
        console.log("1312");
        this.refresh();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  background: #fff;
}
::v-deep .el-dialog__header {
  padding: 0;
}
.fnc {
  color: #8994e5;
}
.Courseware_name {
  width: 240px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  overflow: hidden;
}
.Courseware_state {
  text-align: left;
  width: 172px;
}
.title {
  font-size: 24px;
  text-align: left;
}
.Courseware_title {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background: #696c7a;
}
.Courseware_list {
  width: 500px;
  border: 1px solid #626262;
  margin-top: 16px;
  color: #fff;
  li {
    display: flex;
    height: 40px;
    align-items: center;
    // justify-content: space-between;
  }
}
.upload-demo {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
}
.el-upload__tip {
  width: 100%;
  text-align: left;
  color: #c7c7c7;
}
::v-deep .el-message {
  z-index: 9999;
}
::v-deep .el-dialog {
  background: #414351;
  opacity: 1;

  overflow: hidden;
}
</style>